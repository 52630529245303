











import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IReportDailyOperationalWithdrawDTO } from "./types";

@Component
export default class UnitWithdrawal extends Vue {
  @Prop({
    type: Object as () => Pagination<IReportDailyOperationalWithdrawDTO>,
  })
  readonly dataSourceUnitWithdrawal!: Pagination<IReportDailyOperationalWithdrawDTO>;

  @Prop({ default: false, type: Boolean }) readonly loading!: boolean;
  @Prop({ default: 1, type: Number }) readonly currentPage!: number;

  columns = [
    {
      title: this.$t("lbl_bapu"),
      dataIndex: "bapuNumber",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "doNumber",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
      scopedSlots: { customRender: "isNull" },
    },
  ];
  pagination = {
    showTotal: (total: number): string =>
      this.$t("lbl_total_items", { total }).toString(),
    current: this.currentPage,
    showSizeChanger: true,
    total: this.dataSourceUnitWithdrawal?.totalElements ?? 0,
    pageSize: 10,
  };
  query: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    search: "",
    sorts: "",
  };

  @Watch("dataSourceUnitWithdrawal")
  onChangeDataSource(): void {
    this.pagination.total = this.dataSourceUnitWithdrawal?.totalElements ?? 0;
  }

  @Watch("currentPage")
  onChangeCurrentPage(newValue: number): void {
    if (newValue === 1) this.pagination.current = 1;
  }

  get dataSource() {
    return (
      this.dataSourceUnitWithdrawal?.data?.map((item, i) => ({
        ...item,
        key: i,
      })) ?? []
    );
  }

  handleTableChange(event): void {
    if (event.pagination.current !== this.pagination.current) {
      this.query.page = event.pagination.current - 1;
      this.pagination.current = event.pagination.current;
    }

    if (event.pagination.pageSize !== this.query.limit) {
      this.query.limit = event.pagination.pageSize;
      this.query.page = 0;
      this.pagination.pageSize = event.pagination.pageSize;
      this.pagination.current = 1;
    }
    this.$emit("change-page-limit", this.query);
  }
}
